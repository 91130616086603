import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

import "./article-card.scss"

const ArticleCard = props => {
  return (
    <Link
      className={`article-card ${
        props.featured ? "article-card_featured" : ""
      }`}
      to={props.link}
      title={props.name}
      key={props.title}
    >
      <div className="article-card__image-wrap">
        <Image
          className="article-card__image"
          fluid={props.image.childImageSharp.fluid}
          alt={props.imageAlt}
        />
      </div>
      <div
        className={`article-card__content-wrap ${
          props.language === "malayalam" ? "malayalam" : ""
        }`}
      >
        <h1 className="h2 article-card__title">{props.title}</h1>
        <p className="article-card__description">{props.description}</p>
      </div>
    </Link>
  )
}

ArticleCard.propTypes = {
  key: PropTypes.string,
  title: PropTypes.string,
  link: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  imageAlt: PropTypes.string,
  language: PropTypes.string,
  featured: PropTypes.bool,
}

export default ArticleCard
