import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import ArticleCard from "../components/article-card/article-card"

import "../styles/blog.scss"

const BlogPage = () => {
  const imageBlog = useStaticQuery(graphql`
    query {
      imageNagas: file(relativePath: { eq: "blog-curse-of-the-nagas.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageRudraksha: file(relativePath: { eq: "rudraksha.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageYanthras: file(relativePath: { eq: "yantra-blog.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageGems: file(relativePath: { eq: "gem-blog.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const blogEntries = [
    {
      title: "The Curse of the Nagas",
      description:
        "Nagas are the most divine creatures according to Hinduism. Are the stories about their divinity and strength of their curse just another myth?",
      image: imageBlog.imageNagas,
      imageAlt: "Naga idols in Sarppakkavu",
      link: "/blog/curse-of-the-nagas",
      featured: true,
    },
    {
      title: "സർപ്പശാപ ദുരിതമെങ്ങിനെ അകറ്റാം?",
      description:
        "ഹൈന്ദവ വിശ്വാസം അനുസരിച്ച് ഏറ്റവും ദൈവിക ജീവികളാണ് നാഗങ്ങൾ. അവരുടെ ദൈവത്വത്തെക്കുറിച്ചും അവരുടെ ശാപത്തിന്റെ ശക്തിയെക്കുറിച്ചുമുള്ള കഥകൾ സത്യമോ മിഥ്യയോ?",
      image: imageBlog.imageNagas,
      imageAlt: "Naga idols in Sarppakkavu",
      link: "/blog/curse-of-the-nagas-malayalam",
      language: "malayalam",
    },
    {
      title: "Rudrakashas",
      description: "For All Round Benefits and Absolution from Sins",
      image: imageBlog.imageRudraksha,
      imageAlt: "Rudrakashas held in palm",
      link: "/blog/rudrakshas",
    },
    {
      title: "Yanthras",
      description:
        "Yanthras are the secret keys for establishing resonance with the energies of the macrocosm, for your all round protection.",
      image: imageBlog.imageYanthras,
      imageAlt: "Maha Ganapati yanthra",
      link: "/blog/yanthras",
    },
    {
      title: "Gemstones",
      description: "For controlling your planetary influences",
      image: imageBlog.imageGems,
      imageAlt: "Naga",
      link: "/blog/gems",
    },
  ]

  return (
    <Layout>
      <SEO
        title="Blog"
        description="Explore our collection of articles on various topics in Astrology."
        keyword="astrology, blog, articles"
      />
      <div className="blog-wrap">
        {blogEntries.map(
          ({
            title,
            link,
            description,
            image,
            imageAlt,
            language,
            featured,
          }) => (
            <ArticleCard
              key={title.replace(/\s/g, "")}
              title={title}
              link={link}
              description={description}
              image={image}
              imageAlt={imageAlt}
              language={language}
              featured={featured}
            />
          )
        )}
      </div>
    </Layout>
  )
}

export default BlogPage
